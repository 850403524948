var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-center": "",
        "justify-center": "",
        row: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "text-sm-center",
          attrs: { xs10: "", md8: "", lg6: "", xl4: "" }
        },
        [
          _c("header", [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("@/assets/images/IMG-logo.svg") }
            })
          ]),
          _c(
            "div",
            { staticClass: "elementPanel elementPanel--shadowed" },
            [
              !_vm.isSubmitted
                ? [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      [
                        _c("h2", [_vm._v(_vm._s(_vm.$t("auth.forgot.title")))]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("auth.forgot.instruction")))
                        ]),
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.emailRules,
                            label: _vm.$t("form.formFields.username"),
                            "single-line": "",
                            required: ""
                          },
                          model: {
                            value: _vm.username,
                            callback: function($$v) {
                              _vm.username = $$v
                            },
                            expression: "username"
                          }
                        }),
                        _c(
                          "v-flex",
                          { attrs: { "text-xs-right": "" } },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: { disabled: !_vm.valid },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.submit($event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("auth.forgot.submit")))]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "bottom-link back",
                                on: {
                                  click: function($event) {
                                    return _vm.$router.go(-1)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.back")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.isSubmitted
                ? [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("auth.forgot.title")))]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("auth.forgot.instruction")))
                    ]),
                    _c(
                      "v-flex",
                      { attrs: { "text-xs-right": "" } },
                      [
                        _c("MiButton", { attrs: { to: "/login" } }, [
                          _vm._v(_vm._s(_vm.$t("auth.login")))
                        ])
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }