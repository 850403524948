import { Component, Vue } from 'vue-property-decorator';
import { identifyApplication, identifyHostname } from '@/support/Client';
import axios, { AxiosError, AxiosResponse } from 'axios';
import i18n from '@/i18n';
import ErrorHandler from '@/support/ErrorHandler';

@Component<ForgotPassword>({})
export default class ForgotPassword extends Vue {
  public apiServer: string = identifyHostname() || '';

  private valid = true;

  private username = '';

  private emailRules: any = [
    (v: string) => !! v || 'Username is required',
  ];

  private isSubmitted = false;

  public submit() {
    if (! this.username) {
      return;
    }

    const payload = {
      username: this.username,
    };

    const headers = {};

    if (window.location.hostname !== 'localhost') {
      (headers as any)['X-Application'] = identifyApplication();
      (headers as any)['Accept-Language'] = i18n.locale;
    }

    const client = axios.create({
      baseURL: this.apiServer,
      headers,
    });

    client
      .post('/v1/forgot-password', payload)
      .then((response: AxiosResponse) => {
        this.isSubmitted = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
